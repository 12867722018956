const apiUrl = process.env.API_URL;

if (window.jQuery) {
  (function() {
    (function($) {
      'use strict';
      var getParams, getUsername, main_nav, main_nav_height, nav_sections;
      getParams = function() {
        var key, params, query, raw_vars, v, val, _i, _len, _ref;
        query = window.location.search.substring(1);
        raw_vars = query.split("&");
        params = {};
        for (_i = 0, _len = raw_vars.length; _i < _len; _i++) {
          v = raw_vars[_i];
          _ref = v.split("="), key = _ref[0], val = _ref[1];
          params[key] = decodeURIComponent(val);
        }
        return params;
      };
      getUsername = function() {
        var query, raw_vars;
        query = window.location.pathname;
        raw_vars = query.split("/");
        return raw_vars[raw_vars.length - 1];
      };
      $.ajax({
        url: `${apiUrl}/api/v1/auth/local`,
        type: 'post',
        data: {
          email: 'jameswork514@gmail.com',
          password: '11111111'
        },
        dataType: 'json',
        success: function(res) {
          return $.ajax({
            url: `${apiUrl}/api/v1/users`,
            type: 'get',
            data: {
              athena: 'user1',
              auth: res._id,
              user: getUsername()
            },
            dataType: 'json',
            beforeSend: (function(_this) {
              return function(xhr) {
                return xhr.setRequestHeader("Authorization", "Bearer " + res.token);
              };
            })(this),
            success: (function(_this) {
              return function(data) {
                if (data[0].photo_src) {
                  $(".post-user-avatar").attr('src', data[0].photo_src);
                }
                $(".user-name").html(data[0].first_name + ' ' + data[0].last_name);
                $(".user-interest").html(data[0].interest);
                $(".user-about").html(data[0].about);
                $(".posts-count").html(data[0].posts);
                $(".followers-count").html(data[0].followers);
                $(".following-count").html(data[0].following);
                return $.ajax({
                  url: `${apiUrl}/api/v1/posts`,
                  type: 'get',
                  data: {
                    athena: 'user1',
                    auth: res._id,
                    user: data[0]._id,
                    limit: '20',
                    offset: '0'
                  },
                  dataType: 'json',
                  beforeSend: function(xhr) {
                    return xhr.setRequestHeader("Authorization", "Bearer " + res.token);
                  },
                  success: function(data) {
                    var i, one, x, _i, _len;
                    x = "";
                    for (i = _i = 0, _len = data.length; _i < _len; i = ++_i) {
                      one = data[i];
                      if (one.data[0].type === 'image') {
                        x = x + ("<a class='user-post-one'><img src='" + one.data[0].thumbnail + "' /></a>");
                      } else {
                        x = x + ("<a class='user-post-one'><img src='" + one.data[0].thumbnail + "' /><p>" + (moment.utc(one.data[0].time * 1000).format('m:ss')) + "</p></a>");
                      }
                    }
                    $(".user-posts-wrapper").html(x);
                    return $('.user-post-one').click(function(e) {
                      var fallbackLink, isAndroid, isiOS, run;
                      isiOS = window.navigator.userAgent.match('iPad') || window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod');
                      isAndroid = window.navigator.userAgent.match('Android');
                      fallbackLink = isAndroid ? "https://play.google.com/store/apps/details?id=com.qalorieapp" : "https://apps.apple.com/us/app/qalorie-app/id1538149091";
                      if (isAndroid || isiOS) {
                        window.location = 'qaloriemobile:/' + window.location.pathname;
                        run = function() {
                          return window.location = fallbackLink;
                        };
                        setTimeout(run, 500);
                      }
                      $('#dialog').removeClass('hide');
                      $('#dialog').addClass('show');
                    });
                  }
                });
              };
            })(this)
          });
        }
      });
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });
      $('.back-to-top').click(function() {
        $('html, body').animate({
          scrollTop: 0
        }, 1000);
        return true;
      });
      (new WOW).init();
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('#header').addClass('header-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
        }
      });
      if ($(window).scrollTop() > 40) {
        $('#header').addClass('header-scrolled');
      }
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('#header2').addClass('header-scrolled');
        } else {
          $('#header2').removeClass('header-scrolled');
        }
      });
      if ($(window).scrollTop() > 40) {
        $('#header2').addClass('header-scrolled');
      }
      $('.main-nav a, .mobile-nav a, .scrollto, .footer-menu li a').on('click', function() {
        var target, top_space;
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
          target = $(this.hash);
          if (target.length) {
            top_space = 0;
            if ($('#header').length) {
              top_space = $('#header').outerHeight();
              if (!$('#header').hasClass('header-scrolled')) {
                top_space = top_space - 40;
              }
            }
            if ($('#header2').length) {
              top_space = $('#header2').outerHeight();
              if (!$('#header2').hasClass('header-scrolled')) {
                top_space = top_space - 40;
              }
            }
            $('html, body').animate({
              scrollTop: target.offset().top - top_space
            }, 1000);
            if ($(this).parents('.main-nav, .mobile-nav, .footer-menu').length) {
              $('.main-nav .active, .mobile-nav .active, .footer-menu .active ').removeClass('active');
              $(this).closest('li').addClass('active');
            }
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('.mobile-nav-toggle i').toggleClass('fa-close hamburger_icon');
              $('.mobile-nav-overly').fadeOut();
            }
            return false;
          }
        }
      });
      nav_sections = $('section');
      main_nav = $('.main-nav, .mobile-nav, .footer-menu');
      main_nav_height = $('#header').outerHeight();
      main_nav_height = $('#header2').outerHeight();
      $(window).on('scroll', function() {
        var cur_pos;
        cur_pos = $(this).scrollTop();
        nav_sections.each(function() {
          var bottom, top;
          top = $(this).offset().top - main_nav_height;
          bottom = top + $(this).outerHeight();
          if (cur_pos >= top && cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
            main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
          }
        });
      });
      $('.download-popup, .post-like-button, .post-comment-button, .post-share-button, .post-user-button, .post-followers-button, .post-following-button, .user-follow-button').click(function(e) {
        var fallbackLink, isAndroid, isiOS, run;
        isiOS = window.navigator.userAgent.match('iPad') || window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod');
        isAndroid = window.navigator.userAgent.match('Android');
        fallbackLink = isAndroid ? "https://play.google.com/store/apps/details?id=com.qalorieapp" : "https://apps.apple.com/us/app/qalorie-app/id1538149091";
        if (isAndroid || isiOS) {
          window.location = 'qaloriemobile:/' + window.location.pathname;
          run = function() {
            return window.location = fallbackLink;
          };
          setTimeout(run, 500);
        }
        $('#dialog').removeClass('hide');
        $('#dialog').addClass('show');
        $('body').addClass('scroll');
      });
      $('.dialog-close').click(function(e) {
        $('#dialog').removeClass('show');
        $('#dialog').addClass('hide');
        $('body').removeClass('scroll');
      });
    })(jQuery);

  }).call(this);
}
